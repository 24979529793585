small {
  color: rgba(141, 141, 141, 0.938);
}

#nib {
  max-width: 70%;
}

#small-img {
  max-width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#med-small-img {
  max-width: 60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#med-img {
  max-width: 70%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#large-img {
  max-width: 85%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}


#header {
  font-style: italic;
}

#caption {
  font-style: italic;
}

#spacer {
  color: white;
}