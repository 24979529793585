#about {
  background: rgb(20, 20, 20);
  color: rgb(192, 192, 192);
}

h1, h2, h3, h4, h5, h6, p, nav {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

#article {
  border-bottom: 1px solid gray;
}

#git-link {
  text-decoration: none;
}

#sticky-sidebar {
  position:fixed;
  max-width: 30%;
}
